<template>
  <v-container class="fill-height text-left pa-0 ma-0" fluid>
    <v-row justify="start">
      <v-col cols="12">
        <v-card
          class="fill-height container--fluid pa-0 ma-0"
          flat
          tile
          width="100%"
        >
          <v-slide-x-transition appear>
            <v-img
              class="grey lighten-2"
              height="300"
              src="@/assets/images/poster-pharmaceutic.jpg"
              width="100%"
            >
              <div class="fill-height bottom-gradient" style="width: 100%">
                <v-container class="fill-height align-end pb-15" fluid>
                  <v-row align="end" justify="start">
                    <v-col class="pl-5 pl-md-15" cols="12">
                      <v-sheet
                        :width="$vuetify.breakpoint.xs ? '100%' : 500"
                        class="transition-swing panel-filter-color"
                        elevation="10"
                        height="170"
                        light
                        outlined
                        rounded="lg"
                      >
                        <v-container class="pa-5 ma-5" fluid>
                          <v-row align="start" justify="start" dense>
                            <v-col cols="12">
                              <div class="blue--text mx-1">
                                <span class="text-md-h4">
                                  The best of Baja California
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <div class="indigo--text mx-1">
                                <span class="text-md-h6">
                                  Our pharmacy networks at your fingertips
                                  hands.
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-img>
          </v-slide-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>

    <v-row justify="center">
      <SearchBar :data="pharmaceutics" @search="updateFilteredData" />
    </v-row>

    <v-row justify="center">
      <div
        class="d-flex fill-height fluid pt-8 pb-8 px-5 px-md-10"
        style="width: 100%"
      >
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="start">
            <v-col
              v-for="(item, index) in filteredData"
              :key="index"
              class="d-flex child-flex"
              cols="12"
              sm="6"
              md="4"
            >
              <div class="fill-height">
                <!-- v-if="item.pictures[0] != null" -->
                <v-card hover class="rounded-lg" v-if="item.active">
                  <div style="cursor: pointer; height: 300px; overflow: hidden" v-on:click="showProfile(item.id)">
                  <v-img
                    :lazy-src="require('@/assets/images/no-image.jpg')"
                    :src="
                      item.pictures[0] != null
                        ? item.pictures[0].url
                        : require('@/assets/images/no-image.jpg')
                    "
                    class="white--text align-end justify-start zoom-image"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
                    height="300"
                    position="center"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        align="center"
                        class="fill-height ma-0"
                        justify="center"
                      >
                        <v-progress-circular
                          color="grey lighten-5"
                          indeterminate
                        >
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <div class="white--text mx-2">
                      <p class="text-left font-weight-light text-h5">
                        {{ item.name }}
                      </p>
                    </div>
                  </v-img>

                    <img v-if="item.basic===1 
                        || item.id == 6 
                        || item.id == 7 
                        || item.id == 9 
                        || item.id == 8

                    " src="@/assets/images/icons/basic.png"
                        alt="sello"
                        class="superior-derecha"/>
                    <img v-else-if="item.premium===1
                          || item.id == 1 

                          " 
                        src="@/assets/images/icons/premium.png"
                        alt="sello"
                        class="superior-derecha"/>
                    <img v-else src="@/assets/images/icons/member.png"
                        alt="sello"
                        class="superior-derecha"/>
                    <img v-show="item.excellence===1 
                          || item.id == 1
                          " src="@/assets/images/icons/escudo_coepris.f8331f99.png" alt="sello1"
                        class="superior-derecha-segunda"/>
                    <img v-show="item.repsabi===1
                          || item.id == 1
                        " src="@/assets/images/icons/repssabi.png" alt="sello1"
                        class="superior-derecha-tercera"/>                   
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="pharmaceutics.length === 0">
      <div style="text-align: center;">
        <div>
          {{ this.message }}
        </div>
        <div style="display: inline-block;margin-top: -15px;">
          <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
        </div>
      </div>
    </v-row>    
  </v-container>
</template>

<script>
import pharmaceuticsService from "@/providers/PharmaceuticsService";
import SearchBar from "../searchBar/SearchBar.vue";

export default {
  name: "PharmaceuticsComponent",

  components: {
    SearchBar,
  },

  data: () => ({
    selectedId: 0,
    pharmaceutics: [],
    filteredData: [],
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading our list of excellence pharmacies, please be patient ...",
  }),
  methods: {
    getPharmaceutics() {
      pharmaceuticsService.getViewList(true).then((record) => {
        this.pharmaceutics = record.value;
        this.filteredData = [...this.pharmaceutics];

        if (this.pharmaceutics.length === 0) {
          this.message = "There are no Pharmacies in the catalogue...";
        }
      });
    },

    updateFilteredData(filteredData) {
      this.filteredData = filteredData;
    },

    showProfile(idPharma) {
      this.selectedId = idPharma;
      this.$router.push({
        name: "PharmaceuticProfile",
        params: {
          id: idPharma,
        },
      });
    },
  },
  mounted() {
    this.pharmaceutics = [];
    this.filteredData = [];
    this.getPharmaceutics();
  },
};
</script>

<style scoped>
.superior-derecha {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 80px;
  height: 80px;
}

.superior-derecha-segunda {
  position: absolute;
  top: 60px;
  right: 3px;
  width: 95px;
  height: 95px;
}

.superior-derecha-tercera {
  position: absolute;
  top: 160px;
  right: 12px;
  width: 70px;
  height: 70px;
}    
@media (max-width: 767px) {
  .superior-derecha {
    position: absolute;
    top: -30px;
    right: -25px;
    width: 90px;
    height: 80px;
  }

  .superior-derecha-segunda {
    position: absolute;
    top: 50px;
    right: -3px;
    width: 95px;
    height: 95px;
  }

  .superior-derecha-tercera {
    position: absolute;
    top: 145px;
    right: 7px;
    width: 70px;
    height: 70px;
  }
} 

.v-btn {
  text-transform: none !important;
}
.backcolor {
  background-color: transparent;
}
.panel-filter-color {
  background: rgba(255, 255, 255, 0.767) 100%;
}
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
.zoom-img {
  overflow: hidden;
  border-radius: 10px;
}
.zoom-img > img {
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}
.zoom-img:hover > img {
  transform: scale(1.05);
}
.zoom-image {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  /* VERZÖGERUNG */
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.zoom-image:hover {
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -ms-transform: scale(1.07);
  -o-transform: scale(1.07);
  transform: scale(1.07);
}
</style>
